// import "dotenv/config";

import axios from "axios";
import { doc, runTransaction } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  ArtistType,
  CurrentlyPlayingItemType,
  PlaylistType,
  SessionType,
  SongType,
} from "../types/types";
import { db } from "../services/firebase";
import CountdownTimer from "./CountdownTimer";
import CurrentlyPlaying from "./CurrentlyPlaying";
import { MusicNote, User } from "phosphor-react";
import QRCode from "react-qr-code";

interface ActiveSessionProps {
  sessionData: SessionType;
  token: string;
  playlist: string;
}

const ActiveSession = ({
  sessionData,
  token,
  playlist,
}: ActiveSessionProps) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [shouldQueueSong, setShouldQueueSong] = useState<boolean>(true);
  const [currentlyPlayingItem, setCurrentlyPlayingItem] = useState<
    CurrentlyPlayingItemType | undefined
  >(undefined);
  //   const [playlist, setPlaylist] = useState<PlaylistType>({
  //     id: "2UJ8cTlFuG9mxEyDgXNpC1",
  //     name: "example playlist",
  //   });
  const [votePercentage, setVotePercentage] = useState<number[]>([1, 1]);

  const updateSessionData = async (newDataObject: any) => {
    try {
      const tokenRef = token ?? "";
      const docRef = doc(db, "sessions", tokenRef);
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef);
        if (!sfDoc.exists()) {
          console.log("Document does not exist");
          return;
        }

        transaction.update(docRef, newDataObject);

        //Also add user to votedUsers array
      });
      console.log("Succesfully updated sessionData", newDataObject);
    } catch (e) {
      console.log("Failed to update sessionData");
    }
  };
  //Here we first want to check if something is already playing
  useEffect(() => {
    const getCurrentlyPlaying = async () => {
      try {
        const { data } = await axios.get(
          "https://api.spotify.com/v1/me/player/currently-playing",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("currentlyPlaying", data);
        if (!data) {
          alert("Start a song on spotify and refresh the site!");
          return;
        }
        const duration = data.item.duration_ms;
        const progress = data.progress_ms;
        console.log("duration", duration);
        const newCurrentlyPlayingItemArtists = data.item.artists.map(
          (artist: ArtistType) => {
            return {
              name: artist.name,
              id: artist.id,
            };
          }
        );
        const newCurrentlyPlayingItem: CurrentlyPlayingItemType = {
          name: data.item.name,
          artists: newCurrentlyPlayingItemArtists,
          image: data.item.album.images[1].url,
        };
        setCurrentlyPlayingItem(newCurrentlyPlayingItem);
        console.log("newCurrentlyPlayingItem", newCurrentlyPlayingItem);
        const newVotingEndTime = Date.now() + duration - progress - 10000;
        const newVotingTimeObject = {
          votingEnds: newVotingEndTime,
          shouldGetCurrentlyPlaying: false,
        };
        await updateSessionData(newVotingTimeObject);
      } catch (error) {
        console.log("Error while fetching currently playing", error);
      }
    };

    token && sessionData?.shouldGetCurrentlyPlaying && getCurrentlyPlaying();

    //token && sessionData?.shouldGetCurrentlyPlaying && getCurrentlyPlaying();
  }, [token, sessionData?.shouldGetCurrentlyPlaying]);

  useEffect(() => {
    const getPlaylist = async (playlistId: string) => {
      try {
        const { data } = await axios.get(
          "https://api.spotify.com/v1/playlists/" + playlistId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("data", data);
        const newTracks = data.tracks.items;
        //We only want to save the id, name, artist, image and href of each song
        const newTracksWithOnlyNecessaryData = newTracks.map(
          (track: any, index: number) => {
            const newTrack = {
              id: track.track.id,
              name: track.track.name,
              title: track.track.name,
              artist: track.track.artists[0].name,
              image: track.track.album.images[1].url,
              href: track.track.href,
              index: index,
            };
            return newTrack;
          }
        );
        console.log(
          "newTracksWithOnlyNecessaryData",
          newTracksWithOnlyNecessaryData
        );
        const newSessionData = {
          availableSongs: newTracksWithOnlyNecessaryData,
        };
        await updateSessionData(newSessionData);
      } catch (error) {
        console.log(error);
      }
    };
    token &&
      playlist &&
      getPlaylist(playlist.split("/playlist/")[1].split("?")[0]);
  }, [playlist, token]);

  //Then we want to get the next song alternatives
  useEffect(() => {
    const getNextSongs = async () => {
      console.log("getting next songs");
      if (!sessionData.availableSongs) return;
      const randomIndex1 = Math.floor(
        Math.random() * sessionData?.availableSongs.length
      );
      let randomIndex2 = Math.floor(
        Math.random() * sessionData?.availableSongs.length
      );
      while (randomIndex1 === randomIndex2) {
        randomIndex2 = Math.floor(
          Math.random() * sessionData?.availableSongs.length
        );
      }

      const randomSong1 = sessionData?.availableSongs[randomIndex1];
      const randomSong2 = sessionData?.availableSongs[randomIndex2];

      const newNextSongs = [
        {
          id: randomSong1.id,
          name: randomSong1.name,
          artist: randomSong1.artist,
          href: randomSong1.href,
          image: randomSong1.image,
        },
        {
          id: randomSong2.id,
          name: randomSong2.name,
          artist: randomSong2.artist,
          href: randomSong2.href,
          image: randomSong2.image,
        },
      ];
      const sessionDataObject = {
        nextSongs: newNextSongs,
        isVotingFinished: false,
        isQueued: false,
        shouldFetchNextSongs: false,
        shouldGetCurrentlyPlaying: true,
      };
      await updateSessionData(sessionDataObject);
      //setShouldQueueSong(true);
    };
    let fetchNextSongs: any;
    const availableSongs = sessionData?.availableSongs;
    if (!availableSongs) return;
    if (availableSongs.length < 2) {
      console.log("Not enough songs");
      return;
    }
    if (!initialized) {
      token && getNextSongs();
      setInitialized(true);
      return;
    }
    fetchNextSongs = setTimeout(async () => {
      token && sessionData?.shouldFetchNextSongs && (await getNextSongs());
      //setShouldQueueSong(true);
    }, 12000);

    return () => {
      clearTimeout(fetchNextSongs);
    };
  }, [
    sessionData?.availableSongs,
    sessionData?.shouldFetchNextSongs,
    initialized,
    token,
  ]);

  useEffect(() => {
    let updateShouldQueueSong: any;
    if (!sessionData?.shouldFetchNextSongs) {
      updateShouldQueueSong = setTimeout(async () => {
        setShouldQueueSong(true);
      }, 14000);
    }

    return () => {
      clearTimeout(updateShouldQueueSong);
    };
  }, [sessionData?.shouldFetchNextSongs]);

  const addSongToQueue = async (trackId: string) => {
    console.log("adding track to queue", trackId);
    if (!shouldQueueSong) return;
    try {
      const url = `https://api.spotify.com/v1/me/player/queue?uri=spotify:track:${trackId}`;
      const data = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //Update sessionData
      let newAvailableSongs: SongType[] | undefined | null =
        sessionData.availableSongs;
      newAvailableSongs = newAvailableSongs?.filter(
        (song: any) => song.id !== trackId
      );
      let newPlayedSongs = sessionData.playedSongs;
      newPlayedSongs.push(trackId);
      const newSessionData = {
        availableSongs: newAvailableSongs || null,
        playedSongs: newPlayedSongs,
        votedUsers: [""],
        votes0: 1,
        votes1: 1,
        isQueued: true,
        shouldFetchNextSongs: true,
        isVotingFinished: true,
      };
      await updateSessionData(newSessionData);
      //Add trackId to playedSongs
      //Remove trackid from available songs
      //Set votedUsers to [""]
      //set votes0 and votes 1 to 1
      //set votingEnds to end of next song (perhaps not here)
      setShouldQueueSong(false);
    } catch (error) {
      console.log(error);
    }
  };

  const endVoting = async () => {
    if (!sessionData?.nextSongs) return;
    //Get most voted song
    const votes = [sessionData?.votes0, sessionData.votes1];
    const mostVotedSongIndex = votes.indexOf(Math.max(...votes));

    const mostVotedSong = sessionData?.nextSongs[mostVotedSongIndex];
    console.log("mostVotedSong", mostVotedSong);

    //Add most voted song to queue
    shouldQueueSong && (await addSongToQueue(mostVotedSong.id));
    setShouldQueueSong(false);
  };

  useEffect(() => {
    const totalVotes = sessionData?.votes0 + sessionData?.votes1;
    const newVotes = [sessionData?.votes0, sessionData?.votes1];
    const newVotePercentage = newVotes.map((vote) => (vote / totalVotes) * 100);
    console.log("newVotePercentage", newVotePercentage);
    setVotePercentage(newVotePercentage);
  }, [sessionData?.votes0, sessionData?.votes1]);

  console.log("token", token);

  return (
    <section className="h-[100vh] bg-slate-300 flex flex-col items-center">
      <div className="mt-4 mb-4 text-3xl">
        Demokratisk dansegulv - stem på neste sang!
      </div>
      {sessionData?.votingEnds && (
        <CountdownTimer
          targetDate={sessionData?.votingEnds}
          endVoting={endVoting}
          votingIsFinished={shouldQueueSong}
        />
        //   <div>{currentSongDuration} ms left</div>
      )}
      <div className="flex flex-row w-full items-center justify-center">
        {sessionData?.nextSongs?.map((song: SongType, i: number) => (
          <div
            key={song.id + Date.now()}
            className="w-25vw h-[60vh] overflow-hidden flex items-end justify-end m-8"
            style={{
              borderRightWidth: i === 0 ? "1px" : "0px",
              borderRightColor: "black",
              paddingRight: i === 0 ? "2rem" : "0px",
              marginRight: i === 0 ? "0" : "2rem",
              flexDirection: i === 0 ? "row" : "row-reverse",
            }}
          >
            <div>
              <img
                className="w-[20vw] h-[20vw] object-fill overflow-hidden rounded-lg mb-2"
                src={song.image}
                alt={song.name}
              />
              <div className="w-[20vw] h-12 text-left text-lg font-bold flex flex-row items-center">
                <MusicNote />
                <div className="ml-2">{song.name}</div>
              </div>
              <div className="w-[20vw] h-12 text-left text-md flex flex-row items-center">
                <User />
                <div className="ml-3">{song.artist}</div>
              </div>
              <div className="text-5xl mt-4">
                {votePercentage[i].toFixed(0)}%
              </div>
            </div>
            <div
              className="bg-red-500 w-10 rounded text-left mx-16 p-2 transition-all ease-in-out duration-500"
              style={{
                maxHeight: votePercentage[i].toString() + "%",
                minHeight: votePercentage[i].toString() + "%",
                marginRight: i === 0 ? "20px" : "60px",
                marginLeft: i === 1 ? "20px" : "60px",
              }}
            />
          </div>
        ))}
      </div>
      {currentlyPlayingItem ? (
        <CurrentlyPlaying currentlyPlayingItem={currentlyPlayingItem} />
      ) : (
        <div>No current track</div>
      )}
      {token && (
        <div className="absolute top-[15vh] left-5 mx-2 flex justify-center flex-col items-center">
          <div className="mb-4 text-lg font-bold ">👇 SCAN QR CODE TO VOTE</div>
          <QRCode
            className="w-[15vw] h-[15vw]"
            value={"https://bursdival.no/vote?id=" + token}
          />
        </div>
      )}
    </section>
  );
};

export default ActiveSession;
