// import "dotenv/config";
import firebase from "firebase/compat/app";
import { useContext } from "react";
import ImageGallery from "../ImageGallery";
import { AuthContext } from "../context";
import SignInScreen from "../SignInScreen";

function Gallery() {
  const { user } = useContext(AuthContext);

  return (
    <div className="App">
      {user ? (
        <ImageGallery user={user} />
      ) : (
        <SignInScreen auth={firebase.auth()} />
      )}
    </div>
  );
}

export default Gallery;
