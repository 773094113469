// import "dotenv/config";
import "./App.css";
import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "./services/firebase";
import { ArrowsClockwise, Plus, UploadSimple } from "phosphor-react";
import { useCollectionData } from "react-firebase-hooks/firestore";
const { v4: uuidv4 } = require('uuid');
interface ImageGalleryProps {
  user: User | null;
}

interface ImageElement {
  imageUrl: string;
  timestamp: number;
  userName: string;
}

const imageGalleryConverter: FirestoreDataConverter<ImageElement> = {
  toFirestore(ImageElement: WithFieldValue<ImageElement>): DocumentData {
    const { ...rest } = ImageElement;
    return rest;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ImageElement {
    const data = snapshot.data(options) as ImageElement;
    return {
      imageUrl: data.imageUrl,
      timestamp: data.timestamp,
      userName: data.userName,
    };
  },
};

export const imageGalleryRef = collection(db, "imageGallery2024").withConverter(
  imageGalleryConverter
) as CollectionReference<ImageElement>;

function ImageGalleryMobile({ user }: ImageGalleryProps) {
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [isUploading, setIsUploading] = useState(false);

  const [data] = useCollectionData<ImageElement>(imageGalleryRef);
  const firebaseImageGalleryElements = data ?? [];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) {
      return;
    }
    setSelectedImage(fileList[0]);
  };

  const handleUpload = () => {
    let file = selectedImage;
    if (!file) {
      return;
    }
    const uniqueFileName = `${Date.now()}-${uuidv4()}-${file.name}`;
    console.log("uniqueFileName");
    const imageRef = ref(storage, `gallery/2024/${uniqueFileName}`);
    uploadBytes(imageRef, file)
      .then((snapshot) => {
        setIsUploading(true);
        getDownloadURL(imageRef).then((url) => {
          addDoc(imageGalleryRef, {
            imageUrl: url,
            timestamp: Date.now(),
            userName: user?.displayName || "",
          }).then(() => {
            setSelectedImage(undefined);
            setIsUploading(false);
          });
        });
      })
      .catch((e) => {
        console.log("Error uploading image", e);
      });
  };
  return (
    <div className="">
      <div className="w-full min-h-screen max-md:justify-start md:h-screen md:max-screen overflow-hidden bg-gradient-to-t from-[#e5d787] via-[#e5d787] to-[#12879d] flex flex-col md:flex-row flex-wrap justify-center p-2">
        {firebaseImageGalleryElements
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((image) => {
            const time: string = new Date(image.timestamp)
              .toLocaleTimeString()
              .slice(0, -3);
            return (
              <div className="w-full md:max-w-[50%] md:w-auto md:h-[48%] my-2 md:mx-2 rounded-lg overflow-hidden">
                <img
                  key={"image" + image.imageUrl}
                  className="w-full md:h-full object-contain"
                  src={image.imageUrl}
                  alt=""
                />
                <div className="h-10 z-20 w-full bg-black text-white flex flex-row justify-between items-center px-2">
                  <div>{image.userName ?? ""}</div>
                  <div>{time}</div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="fixed bottom-5 w-full h-[60px] flex items-center justify-center">
        {selectedImage && (
          <button
            className="w-3/5 h-[60px] text-lg bg-[#245633] border-white text-white border-2 rounded flex items-center justify-center"
            onClick={handleUpload}
          >
            <span className="mr-2">{isUploading ? "Laster opp.." : "Last opp bilde"}</span>
            <UploadSimple size={24} />
          </button>
        )}
        <label
          className="overflow-hidden absolute right-3 h-[60px] w-[60px] rounded-full bg-[#245633] flex items-center justify-center cursor-pointer text-4xl text-white border-white border-2"
          htmlFor="imageUpload"
        >
          <input hidden id="imageUpload" type="file" onChange={handleChange} />
          {selectedImage ? (
            <ArrowsClockwise size={40} color="white" />
          ) : (
            <Plus size={40} color="white" />
          )}
        </label>
      </div>
    </div>
  );
}

export default ImageGalleryMobile;
