// import "dotenv/config";
import firebase from "firebase/compat/app"
import { useContext } from "react"
import { AuthContext } from "../context"
import SignInScreen from "../SignInScreen"
import VotingList from "../components/BestCostumeNomination"

import "../index.css"

function BestCostume() {
  const { user } = useContext(AuthContext)

  return (
    <div className="App bg-gradient-to-t from-[#e5d787] via-[#e5d787] to-[#12879d]">
      {user ? <VotingList /> : <SignInScreen auth={firebase.auth()} />}
    </div>
  )
}

export default BestCostume
