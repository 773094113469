import firebase from "firebase/compat/app";
import { useContext, useState } from "react";
import { AuthContext } from "../context";
import { List, UserCircle, SignOut } from "phosphor-react";

function Navbar() {
  const { user } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="h-12 w-full flex flex-row justify-between items-center bg-[#FADA6D] px-2">
      <div className="flex flex-row items-center">
        <div className="relative">
          <List size={24} className="cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)} />
          {isMenuOpen && (
            <div className="absolute z-[999] top-full left-0 bg-white shadow-md rounded-md py-2 mt-1 w-max min-w-[200px]">
              <a href="/" className="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap">Bilder</a>
              <a href="/costume" className="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap">Beste kostyme</a>
              {/* Add more internal links as needed */}
              {user && (
                <div className="flex flex-row items-center text-md px-4 py-2">
                  <UserCircle size={24} />
                  <h3 className="ml-2 whitespace-nowrap">{user?.displayName}</h3>
                </div>
              )}
              {user && (
                <div className="flex flex-row items-center text-red-800 text-md px-4 py-2">
                  <button className="flex items-center w-full" onClick={() => firebase.auth().signOut()}>
                    <span className="mr-2">Sign Out</span>
                    <SignOut size={20} />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        
      </div>
      <h2 className="text-xl text-center font-extrabold absolute left-1/2 transform -translate-x-1/2 text-[#12879d]">
        <a href="/">BURSDIVAL</a>
      </h2>
      
    </div>
  );
}

export default Navbar;
