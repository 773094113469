// import "dotenv/config";
import firebase from "firebase/compat/app";
import { SpotifyLogo } from "phosphor-react";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context";
import SignInScreen from "../SignInScreen";
import { db } from "../services/firebase";
import { runTransaction, setDoc, doc, onSnapshot } from "firebase/firestore";
import ActiveSession from "./ActiveSession";
import { PlaylistType, SessionType } from "../types/types";

function BattleScreen() {
  const [token, setToken] = useState<string | null>("");
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [sessionData, setSessionData] = useState<SessionType | any>(null);
  const [playlist, setPlaylist] = useState<string>(
    "https://open.spotify.com/playlist/2UJ8cTlFuG9mxEyDgXNpC1?si=2acb1b00638c4175"
  );

  ///---------Token stuff-----------------------
  const CLIENT_ID = "81f65b47cb6b491cba2065b1eb580917";
  const REDIRECT_URI = "https://bursdival.no/battle";
  //   const REDIRECT_URI = "https://bursdival.no/vote";
  const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
  const RESPONSE_TYPE = "token";
  const SCOPE =
    "user-read-currently-playing user-read-playback-state user-modify-playback-state";

  useEffect(() => {
    const hash = window.location.hash;
    let token = window.localStorage.getItem("token");

    if (!token && hash) {
      if (!hash) return;
      const subString = hash.substring(1);
      if (!subString) return;
      const split = subString.split("&");
      if (!split) return;
      const access_token = split.find((elem) =>
        elem.startsWith("access_token")
      );
      if (!access_token) return;
      token = access_token.split("=")[1];

      if (!token) return;

      window.location.hash = "";
      window.localStorage.setItem("token", token);
    }

    setToken(token);
  }, []);

  const logout = () => {
    setToken("");
    window.localStorage.removeItem("token");
  };

  //--------END OF TOKEN STUFF--------

  //First check if we have a session stored in cookies
  //If we do call fetchExistingSession()
  //If not, start new session with startSession()

  useEffect(() => {
    const startSession = async () => {
      if (!token) return;
      try {
        //Create firestore session based on token. Should perhaps be based on refreshtoken, since token expires after 1 hour?
        const sessionObject: SessionType = {
          id: token,
          playlistId: "'",
          playedSongs: [""],
          availableSongs: null,
          nextSongs: null,
          votingEnds: Date.now() + 30000,
          votes0: 1,
          votes1: 1,
          votedUsers: [""],
          isQueued: true,
          isVotingFinished: true,
          shouldFetchNextSongs: true,
          shouldGetCurrentlyPlaying: false,
        };
        const newSessionRef = await setDoc(
          doc(db, "sessions", token),
          sessionObject,
          { merge: true }
        );
        setSessionId(token); //should perhaps be stored in cookies as well
        console.log("newSessionRef", newSessionRef);

        console.log(`Session document created with ID: ${token}`);
        //Listen to session
        const unsub = onSnapshot(doc(db, "sessions", token), (doc) => {
          console.log("Current data: ", doc.data());
          if (!doc.data()) return;
          const newSessionData: any = doc.data();
          setSessionData(newSessionData);
        });
        return token;
      } catch (error) {
        console.error("Error creating session document:", error);
        throw error;
      }
    };
    //If we get token and session is not started, start session
    if (token && !sessionId) {
      startSession();
    }
  }, [token, sessionId]);

  return (
    <div>
      {!token ? (
        <a
          href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}`}
          className="h-12 w-48 rounded-full bg-lime-600 flex flex-row justify-center items-center text-white"
        >
          <SpotifyLogo size={30} />
          <div className="ml-2">Sign in to Spotify</div>
        </a>
      ) : (
        <div className="flex-row flex items-center">
          <button
            className="h-12 w-48 rounded-full bg-red-600 flex flex-row justify-center items-center text-white"
            onClick={logout}
          >
            <SpotifyLogo size={30} />
            <div className="ml-2">Sign out</div>
          </button>
          <label className="ml-4 w-full flex item-start">
            Spotifyliste:{" "}
            <input
              className="ml-2 w-[70%]"
              value={playlist}
              onChange={(e) => setPlaylist(e.target.value)}
            />
          </label>
        </div>
      )}
      {sessionData && token ? (
        <ActiveSession
          sessionData={sessionData}
          token={token}
          playlist={playlist}
        />
      ) : (
        <div>Session not started</div>
      )}
    </div>
  );
}

export default BattleScreen;
