import { useEffect } from "react";
import ExpiredNotice from "./ExpiredTimerNotice";
import ShowCounter from "./ShowCounter";
import { useCountdown } from "./useCountdown";

const CountdownTimer = ({ targetDate, endVoting, votingIsFinished }: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      if (votingIsFinished) {
        console.log("CountdownTimer - ending voting");
        endVoting();
      }
    }
  }, [days, hours, minutes, seconds, votingIsFinished]);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return <ShowCounter minutes={minutes} seconds={seconds} />;
  }
};

export default CountdownTimer;
