// import "dotenv/config";
import firebase from "firebase/compat/app";
import { useContext } from "react";
import DJPlayingScreen from "../components/DJPlayingScreen";
import { AuthContext } from "../context";
import SignInScreen from "../SignInScreen";

function DJ() {
  const { user } = useContext(AuthContext);

  return (
    <div className="App">
      {user ? <DJPlayingScreen /> : <SignInScreen auth={firebase.auth()} />}
    </div>
  );
}

export default DJ;
