// import "dotenv/config";
import axios from "axios";
import { runTransaction, setDoc, doc, onSnapshot } from "firebase/firestore";
import { MusicNote, SpotifyLogo, User } from "phosphor-react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context";
import { db } from "../services/firebase";
import { SessionType, SongType } from "../types/types";
import CountdownTimer from "./CountdownTimer";

function VotingScreen() {
  const { user } = useContext(AuthContext);
  const [token, setToken] = useState<string | null>("");
  const [isVotingDisabled, setIsVotingDisabled] = useState<boolean>(false);
  const [hasVoted, setHasVoted] = useState<boolean>(false);
  const [votingEndTime, setVotingEndtime] = useState<number | undefined>(20000);
  const [sessionData, setSessionData] = useState<SessionType | any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  //Get sessionData from firestore

  useEffect(() => {
    //Get firestore session data
    //Listen to session
    if (!token) {
      console.log("No token found");
      return;
    }
    const unsub = onSnapshot(doc(db, "sessions", token), (doc) => {
      if (!doc.data()) return;
      console.log("Current data - Voting screen: ", doc.data());
      const newSessionData: any = doc.data();
      setSessionData(newSessionData);
    });
  }, [token]);

  useEffect(() => {
    let url = window.location.href;
    console.log("url", url);
    const id = url.split("/vote?id=")[1];
    if (id) {
      setToken(id);
      console.log("id found", id);
    } else {
      console.log("id", id);
    }
  }, []);

  useEffect(() => {
    setHasVoted(sessionData?.votedUsers.includes(user.uid));
    if (sessionData?.votedUsers.length < 2) {
      setIsVotingDisabled(false);
    }
  }, [sessionData?.votedUsers, user]);

  const voteForSong = async (index: number) => {
    if (isVotingDisabled) return;
    try {
      const tokenRef = token ?? "";
      const docRef = doc(db, "sessions", tokenRef);
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef);
        if (!sfDoc.exists()) {
          console.log("Document does not exist");
          return;
        }
        const newVotedUsers = sfDoc.data().votedUsers;
        if (newVotedUsers.includes(user.uid)) {
          alert("You have already voted!");
          return;
        }
        if (index === 0) {
          const newVotes = sfDoc.data()?.votes0 + 1;
          newVotedUsers.push(user.uid);
          transaction.update(docRef, {
            votes0: newVotes,
            votedUsers: newVotedUsers,
          });
        } else if (index === 1) {
          const newVotes = sfDoc.data()?.votes1 + 1;
          newVotedUsers.push(user.uid);
          transaction.update(docRef, {
            votes1: newVotes,
            votedUsers: newVotedUsers,
          });
        }
        //Also add user to votedUsers array
      });
      console.log("Succesfully updated votes" + index);
    } catch (e) {
      console.log("Failed to update votes" + index, e);
      console.log("user", user);
    }
  };

  const endVoting = () => {
    console.log("voting ended");
    setIsVotingDisabled(true);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full h-screen bg-slate-300 flex items-center flex-col">
        <div className="text-lg my-4">DU bestemmer neste sang!!</div>
        {votingEndTime && (
          <CountdownTimer
            targetDate={sessionData?.votingEnds}
            endVoting={endVoting}
            votingIsFinished={sessionData?.isVotingFinished}
          />
          //   <div>{currentSongDuration} ms left</div>
        )}
        <div className="flex flex-col w-full items-center justify-center">
          {sessionData?.nextSongs?.map((song: SongType, i: number) => (
            <div
              key={song.id + Date.now()}
              className="w-[95%] p-2 bg-slate-400 min-h-[15vh] overflow-hidden flex flex-row items-center justify-start mt-5 rounded-lg border-2"
              style={{
                borderColor: selectedIndex === i ? "black" : "bg-slate-400",
              }}
              onClick={() => setSelectedIndex(i)}
            >
              <div className="flex flex-row items-center justify-start w-full">
                <img
                  className="w-[10vh] h-[10vh] object-fill overflow-hidden rounded-lg mb-2"
                  src={song.image}
                  alt={song.name}
                />
                <div className="ml-2 w-[70%]">
                  <div className="w-[80%] text-left text-md font-bold flex flex-row items-center">
                    <MusicNote />
                    <div className="ml-2 w-full">{song.name}</div>
                  </div>
                  <div className="w-[80%] h-12 text-left text-md flex flex-row items-center">
                    <User />
                    <div className="ml-3 w-full">{song.artist}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {hasVoted && <div className="text-lg my-4">Takk for din stemme!</div>}
        {!hasVoted && !isVotingDisabled && (
          <button
            className="w-[90%] h-[8vh] mt-10 rounded-lg text-2xl font-bold"
            style={{
              backgroundColor: selectedIndex !== undefined ? "green" : "grey",
              color: selectedIndex !== undefined ? "white" : "black",
            }}
            onClick={() => {
              if (selectedIndex === undefined) {
                alert("Please select a song");
              } else {
                voteForSong(selectedIndex);
              }
            }}
          >
            STEM
          </button>
        )}
      </div>
    </div>
  );
}

export default VotingScreen;
