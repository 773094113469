import React, { useContext, useEffect, useState } from "react";
import { db, storage } from "../services/firebase"; // Import your Firebase instance
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { AuthContext } from "../context";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

//const { user } = useContext(AuthContext)

interface Nomination {
  id: string;
  name: string;
  votes: number;
  imageUrl?: string;
}

function VotingList() {
  const [nominations, setNominations] = useState<Nomination[]>([]);
  const [name, setName] = useState("");
  const [isLoadingVote, setIsLoadingVote] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchNominations = async () => {
      // Create a reference to the nominations collection
      const nominationsRef = collection(db, "nominations2024");

      // Fetch nominations from Firestore
      const querySnapshot = await getDocs(nominationsRef);

      // Map the data as Nomination type
      const nominationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Nomination[];

      nominationsData.sort((a, b) => b.votes - a.votes);

      setNominations(nominationsData);
    };

    fetchNominations();
  }, []);

  const handleVote = async (nominationId: string) => {
    if (!user) {
      // User is not authenticated; handle authentication
      return;
    }

    // Check the user's vote count in the vote collection
    const voteCollectionRef = collection(db, "votes24");
    const userVotesQuery = query(
      voteCollectionRef,
      where("userId", "==", user.uid)
    );
    const userVotesSnapshot = await getDocs(userVotesQuery);
    const userVoteCount = userVotesSnapshot.size;

    if (userVoteCount >= 3) {
      // The user has reached the vote limit
      alert("Du har brukt alle dine stemmer (3 stemmer).");
      return;
    }

    try {
      // Allow the user to vote
      // Create a new vote document in the 'votes' collection
      await addDoc(voteCollectionRef, {
        userId: user.uid,
        nominationId,
        timestamp: new Date(),
      });
      setIsLoadingVote(true);
      // Update the votes count for the nominated item in the 'nominations' collection
      const nominationRef = doc(db, "nominations2024", nominationId);
      await updateDoc(nominationRef, {
        votes: increment(1),
      });
      setIsLoadingVote(false);
    } catch (error) {
      console.error("Error updating votes:", error);
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setImage(file || null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Upload the image (if selected)
    let imageUrl = "";
    if (image) {
      try {
        setIsUploading(true);

        const imageRef = ref(storage, `nomination_images/2024/${image.name}`);
        await uploadBytes(imageRef, image);

        imageUrl = await getDownloadURL(imageRef);
      } catch (error) {
        console.error("Error uploading image:", error);
        setIsUploading(false);
        return;
      }
    }

    // Add the nomination to Firestore
    try {
      const nominationsRef = collection(db, "nominations2024");
      const nominationData = {
        name,
        imageUrl,
        votes: 0,
      };

      const docRef = await addDoc(nominationsRef, nominationData);

      // Clear the input fields
      setName("");
      setImage(null);
      setIsUploading(false);

      // Update the state to include the new nomination
      setNominations((prevNominations) => [
        ...prevNominations,
        { ...nominationData, id: docRef.id },
      ]);
    } catch (error) {
      console.error("Error adding nomination:", error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Last opp et verdig kostyme:</h2>
      <div className="mt-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <input
              type="text"
              placeholder="Hils på vedkommende, og fyll inn navn"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 rounded-md shadow-md focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          {!image && (
            <div className="mb-2">
              <label className="relative inline-flex items-center bg-blue-500 text-white py-2 px-4 rounded-md cursor-pointer mr-2">
                <span className="mr-2">Ta bilde</span>
                <input
                  type="file"
                  accept="image/*"
                  capture="user"
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </label>
              <label className="relative inline-flex items-center bg-blue-500 text-white py-2 px-4 rounded-md cursor-pointer">
                <span className="mr-2">
                  {image ? "Bilde valgt" : "Velg bilde"}
                </span>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </label>
            </div>
          )}

          {image && (
            <div className="mb-1 flex-row flex items-center justify-center">
              <img
                src={URL.createObjectURL(image)}
                alt="Nominert kostyme"
                className="w-[10vh] h-[10vh] rounded-md object-contain"
              />
              <div className="mx-4 ">Valgt bilde</div>
              <button
                className="bg-red-500 text-sm text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none"
                onClick={() => setImage(null)}
              >
                x Fjern
              </button>
            </div>
          )}

          {image && (

            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none"
            >
              {isUploading ? "Uploading..." : "Nomineeeeer"}
            </button>
          )}

        </form>
      </div>
      <h2 className="text-2xl font-bold mt-8 mb-4">Nominasjoner:</h2>
      <h2 className="text-sm font-bold mb-4">
        (Du kan stemme 3 ganger totalt)
      </h2>
      <ul>
        {nominations.map((nomination) => (
          <li
            key={nomination.id}
            className="bg-white shadow-lg p-4 rounded-md mb-4 flex items-center flex-col"
          >
            <div className="mb-2">
              <span className="text-lg font-semibold">{nomination.name}</span> -{" "}
              <span className="text-gray-600">{nomination.votes} stemmer</span>
            </div>
            {nomination.imageUrl && (
              <img
                src={nomination.imageUrl}
                alt={`nomination for ${nomination.name}`}
                className="max-w-full h-auto mb-2"
              />
            )}
            <button
              onClick={() => handleVote(nomination.id)}
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              {isLoadingVote ? "Stemmer.." : "Gi din stemme"}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VotingList;
