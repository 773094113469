// import "dotenv/config";
import firebase from "firebase/compat/app";
import { useContext } from "react";
import BattleScreen from "../components/BattleScreen";
import { AuthContext } from "../context";
import SignInScreen from "../SignInScreen";

function Battle() {
  const { user } = useContext(AuthContext);

  return (
    <div className="App">
      {user ? <BattleScreen /> : <SignInScreen auth={firebase.auth()} />}
    </div>
  );
}

export default Battle;
