import { CurrentlyPlayingItemType } from "../types/types";
import { MusicNote, User } from "phosphor-react";

interface CurrentlyPlayingProps {
  currentlyPlayingItem: CurrentlyPlayingItemType;
}

const CurrentlyPlaying = ({ currentlyPlayingItem }: CurrentlyPlayingProps) => {
  return (
    <div className="w-[80vw] mb-10 ml-10 p-2">
      <div className="w-full text-left text-sm mb-1">Nå spilles</div>
      <div className="flex flex-row w-[80%] self-center">
        <img
          className="w-[7vw] h-[7vw] object-fill overflow-hidden rounded-lg mb-2"
          src={currentlyPlayingItem.image}
          alt={currentlyPlayingItem.name + " album cover"}
        />
        <div className="flex flex-col items-start justify-center ml-4 w-full">
          <div className="w-full h-8 text-left text-lg font-bold flex flex-row items-center">
            <MusicNote />
            <div className="ml-2">{currentlyPlayingItem.name}</div>
          </div>

          <div className="w-full h-8 text-left text-md flex flex-row items-center">
            <User />
            <div className="ml-3">
              {currentlyPlayingItem.artists.map((artist, i) => {
                let returnStr = artist.name;
                if (i < currentlyPlayingItem.artists.length - 1) {
                  returnStr += ", ";
                }
                return returnStr;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentlyPlaying;
