import DateTimeDisplay from "./DateTimeDisplay";

const ShowCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <div className="show-counter">
      <div className="flex flex-row text-lg font-bold">
        <DateTimeDisplay value={minutes} type={"min"} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"sek"} isDanger={false} />
      </div>
    </div>
  );
};

export default ShowCounter;
