// import "dotenv/config";
import firebase from "firebase/compat/app";
import { useContext, useEffect, useState } from "react";
import VotingScreen from "../components/VotingScreen";
import { AuthContext } from "../context";
import SignInScreen from "../SignInScreen";

function Vote() {
  const { user } = useContext(AuthContext);
  const [redirectUrl, setRedirectUrl] = useState<string>();

  useEffect(() => {
    let url = window.location.href;
    console.log("url", url);
    const id = url.split("/vote?id=")[1];
    if (id) {
      setRedirectUrl("/vote?id=" + id);
      console.log("/vote?id=" + id);
    } else {
      console.log("id", id);
    }
  }, []);

  return (
    <div className="App">
      {user ? (
        <VotingScreen />
      ) : (
        <SignInScreen redirectUrl={redirectUrl} auth={firebase.auth()} />
      )}
    </div>
  );
}

export default Vote;
