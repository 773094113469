// import "dotenv/config";
// Import the functions you need from the SDKs
import { initializeApp } from "firebase/app";
import type { FirebaseOptions } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig: FirebaseOptions = {
//   apiKey: process.env.API_KEY,
//   authDomain: process.env.AUTH_DOMAIN,
//   databaseURL: process.env.DATABASE_URL,
//   projectId: process.env.PROJECT_ID,
//   storageBucket: process.env.STORAGE_BUCKET,
//   messagingSenderId: process.env.MESSAGING_SENDER_ID,
//   appId: process.env.APP_ID,
//   measurementId: process.env.MEASUREMENT_ID,
// };

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyC5MjfOBUOBPcl1OqS7L_qNDFk4mbf8xB4",
  authDomain: "bursdival.firebaseapp.com",
  projectId: "bursdival",
  storageBucket: "bursdival.appspot.com",
  messagingSenderId: "786777047708",
  appId: "1:786777047708:web:17212d1c8762d242326359",
  measurementId: "G-V9VNGPRYH3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const imageRef = ref(storage);

export default app;
