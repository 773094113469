// import "dotenv/config";
import "./App.css";
import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "./services/firebase";
import { ArrowsClockwise, Plus } from "phosphor-react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Image from "./components/Image";
interface ImageGalleryProps {
  user: User | null;
}

interface ImageElement {
  imageUrl: string;
  timestamp: number;
  userName: string;
}

const imageGalleryConverter: FirestoreDataConverter<ImageElement> = {
  toFirestore(ImageElement: WithFieldValue<ImageElement>): DocumentData {
    const { ...rest } = ImageElement;
    return rest;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ImageElement {
    const data = snapshot.data(options) as ImageElement;
    return {
      imageUrl: data.imageUrl,
      timestamp: data.timestamp,
      userName: data.userName,
    };
  },
};

export const imageGalleryRef = collection(db, "imageGallery2024").withConverter(
  imageGalleryConverter
) as CollectionReference<ImageElement>;

function ImageGallery({ user }: ImageGalleryProps) {
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const [isUploading, setIsUploading] = useState(false);

  const [data] = useCollectionData<ImageElement>(imageGalleryRef);
  const firebaseImageGalleryElements = data ?? [];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) {
      return;
    }
    setSelectedImage(fileList[0]);
  };

  const handleUpload = () => {
    let file = selectedImage;
    if (!file) {
      return;
    }
    const imageRef = ref(storage, file.name);
    uploadBytes(imageRef, file)
      .then((snapshot) => {
        setIsUploading(true);
        getDownloadURL(imageRef).then((url) => {
          addDoc(imageGalleryRef, {
            imageUrl: url,
            timestamp: Date.now(),
            userName: user?.displayName || "",
          }).then(() => {
            setSelectedImage(undefined);
            setIsUploading(false);
          });
        });
      })
      .catch((e) => {
        console.log("Error uploading image", e);
      });
  };
  const [activeIndex, setActiveIndex] = useState(-1);
  //Create a new Array with the displayed images
  const [displayedImages, setDisplayedImages] = useState<ImageElement[] | []>(
    []
  );
  //updateActiveIndex every 5 seconds in useEffect, max index = firebaseImageGalleryElements.length
  useEffect(() => {
    const interval = setInterval(() => {
      // const newActiveIndex =
      //   activeIndex === firebaseImageGalleryElements.length - 1
      //     ? 0
      //     : activeIndex + 1;

      setActiveIndex((prev) => {
        if (prev === firebaseImageGalleryElements.length - 1) {
          return 0;
        }
        return prev + 1;
      });
      // const newDisplayedImages = [...displayedImages];
      // newDisplayedImages.push(firebaseImageGalleryElements[newActiveIndex]);
      // console.log("newActiveIndex", newActiveIndex);
      // console.log("newDisplayedImages", newDisplayedImages);
      // setActiveIndex(newActiveIndex);
      // setDisplayedImages(newDisplayedImages);
    }, 5000);
    return () => clearInterval(interval);
  }, [
    activeIndex,
    firebaseImageGalleryElements.length,
    displayedImages,
    setDisplayedImages,
    firebaseImageGalleryElements,
  ]);

  return (
    <div className="overflow-hidden max-w-screen max-h-screen">
      <div className="absolute left-4 w-96 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
      <div className="absolute right-4 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
      <div className="absolute bottom-8 left-40 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
      <div className="w-full h-screen md:max-h-screen bg-gradient-to-t from-[#e5d787] via-[#e5d787] to-[#12879d] flex flex-col md:flex-row justify-center animate-gradient-y">
        <div className="flex flex-row items-center justify-center w-[60vw]">
          {firebaseImageGalleryElements
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((image, i) => {
              return (
                <Image
                  key={i}
                  isActive={activeIndex === i}
                  image={image}
                  i={i}
                />
              );
            })}
        </div>
      </div>
      <div className="fixed right-5 text-xl top-0 h-[60px] flex items-center justify-center">
            <div>
              {`${activeIndex + 1 }/${firebaseImageGalleryElements.length}`}
            </div>
      </div>
      {/* <div className="fixed bottom-5 w-full h-[60px] flex items-center justify-center">
        {selectedImage && (
          <button
            className="w-3/5 h-[60px] text-lg bg-[#245633] border-white text-white border-2 rounded"
            onClick={handleUpload}
          >
            {isUploading ? "Uploading.." : "Upload"}
          </button>
        )}
        <label
          className="overflow-hidden absolute right-3 h-[60px] w-[60px] rounded-full bg-[#245633] flex items-center justify-center cursor-pointer text-4xl text-white border-white border-2"
          htmlFor="imageUpload"
        >
          <input hidden id="imageUpload" type="file" onChange={handleChange} />
          {selectedImage ? (
            <ArrowsClockwise size={40} color="white" />
          ) : (
            <Plus size={40} color="white" />
          )}
        </label>
      </div> */}
    </div>
  );
}

export default ImageGallery;
