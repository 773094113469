//1.
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

//2.
export const AuthContext = React.createContext();

//3.
export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(setUser);
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
