const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Stemmingen er lukket</span>
      <p>Gjør dere klare for neste sang!</p>
    </div>
  );
};

export default ExpiredNotice;
