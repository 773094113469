import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import heroImage from "./assets/bursdival24.jpg";

const SignInScreen = (props: any) => {
  console.log("SignInScreen props", props);
  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(props.auth);
    ui.start(".firebase-auth-container", {
      signInFlow: "popup",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      signInSuccessUrl: props.redirectUrl ?? "/",
      privacyPolicyUrl: "/privacy",
    });
  }, [props.auth, props.redirectUrl]);
  return (
    <div className="h-screen bg-gradient-to-b from-[#FADA6D] via-[#FADA6D] to-[#12879d] pt-4 md:pt--4">
      <img
        src={heroImage}
        alt="tre gode venner"
        className="w-full object-contain mb-8 md:max-h-[45%]"
      />
      <span className="absolute bottom-0 left-0 h-60 w-screen">
        <div className="text-lg font-bold">Log in to get started</div>
        <div className="firebase-auth-container w-full" />
      </span>
    </div>
  );
};

export default SignInScreen;
