// import "dotenv/config";
import sample from "../assets/wow.mp4";

function DJPlayingScreen() {
  return (
    <div>
      <video className="object-fill w-screen h-screen" autoPlay loop muted>
        <source src={sample} type="video/mp4" />
      </video>
      <div className="absolute text-[120px] text-white left-0 top-12 h-40 w-full  z-50 flex items-center justify-center">
        <input
          className="bg-[rgba(0,0,0,0.7)] text-white text-center w-full h-full"
          type="text"
        ></input>
      </div>
    </div>
  );
}

export default DJPlayingScreen;
